<template>
  <div>
      <div class="main_box">
        <div class="top_fanhui">  <span @click="huitui"> <i class="el-icon-arrow-left"></i> 返回</span>  </div>

        <div class="shuoming_txt">
            <div class="shouming_tetx">
               <p>说明：</p> 
               <p>容量套餐是什么？</p> 
               <p>答：容量套餐是餐亿本提供的存储容量扩展服务，当您的网盘存储容量不足时，可以通过购买容量套餐进行容量扩充，存储更多文件。</p> 
               <p>容量套餐购买是否受身份限制？</p> 
               <p>答：任何身份用户均可购买容量套餐，购买后容量套餐立即生效 。</p>
               <p>任何身份的用户购买同一容量套餐，均享受同等价格。</p> 
               <p>容量套餐的有效期如何计算？</p> 
               <p>答：当您成功购买容量套餐后，扩展容量立即生效，容量套餐的有效期为一年，有效期按照自然年进行计算。</p> 
               <p>当购买一个容量套餐时，如：</p> 
               <p>您于2020年2月1日购买一年的容量套餐， 则可使用至2021年1月30日 23:59:59；</p> 
               <p>当购买多个容量套餐时，容量进行叠加，如：</p> 
               <p>您于2020年2月1日购买200G的容量套餐，2020年4月17日再购买300G的容量套餐，在2020年4月17日到2021年2月1日期间，您可使用的容量套餐总和为500G。在2021年2月1日到2021年4月6日期间，您可使用的容量套餐为未到期的300G；</p> 
               <p> 容量套餐到期后，容量该如何处理？</p>
               <p>答：如您已购买的容量套餐到期，未进行续费，到期后所购买的容量将被收回。</p> 
               <p>当收回容量时您网盘中已存储的文件，小于当前可使用容量，则文件将不受任何影响，仍可正常使用。</p> 
               <p>当收回容量时您网盘中已存储的文件，超出或等于当前可使用容量，超出可使用容量部分的文件不会被删除，但你将无法向网盘中存储文件，即上传、转存、离线下载等功能暂不可用，其他功能不受影响。</p> 
            </div>
        </div>

        <!-- <div class="shuoming_btn">同意</div> -->
      </div>



  </div>
</template>

<script>
export default {

methods:{
    huitui(){
        console.log('------------------------');
        this.$router.push({
          path: '/cloudStorage'
        })
    },
}
}
</script>

<style>
.top_fanhui{
    width: 100%;
    height: 60px;
    line-height: 60px;
    padding: 0 0 0 50px;
}

.main_box{
    width: 100%;
    height: 660px;
    background-color: #fff;
    margin-top: 30px;

}
.shuoming_txt{
    width: 80%;
    height: 500px;
    margin: auto;
}
.shouming_tetx >p{
        line-height: 24px;
    }
.shuoming_btn{
    width: 200px;
    height: 40px;
    background-color: #f84e4e;
    color: #fff;
    text-align: center;
    line-height: 40px;
    border-radius: 6px;
    margin: 0 auto;
}


</style>